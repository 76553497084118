





































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { AttachmentHostType, AuditFlowScope } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  name: "ApplicationPaymentList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    SimpleUploadImage,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class ApplicationPaymentList extends Vue {
  typeList: any = [];
  queryForm = {
    validationStatus: undefined,
    applicantName: undefined,
    title: undefined,
    isAudited: true,
  };
  projectCategory: any = []; // 项目类型
  projectDomain: any = []; // 项目领域
  activityClass: any = []; // 活动分类
  yesOrNoList = [
    {
      key: "成功",
      value: "Success",
    },
    {
      key: "失败",
      value: "Fail",
    },
  ];
  isShow = false;
  isViewShow = false;
  file = "";
  invoiceNumber = "";
  invoiceNumbers: any = [];
  dataId = 0;
  isAccountShow = false;
  accountList: any = [];
  account: any = "";
  tenantBankAccountId = 0;
  id = 0;
  detailId = 0;

  get hostTypeFile() {
    return AttachmentHostType.ApplicationPayment;
  }
  get uploadParameter() {
    return { hostType: this.hostType };
  }
  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  created() {
    this.fetchEnumType();
    if (
      this.$route.name === "applicationPaymentDetail" &&
      this.$route.params.id
    ) {
      this.detailId = parseInt(this.$route.params.id);
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentProjectCategory",
      })
      .then((res) => {
        this.projectCategory = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentProjectDomain",
      })
      .then((res) => {
        this.projectDomain = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplicationPaymentActivityClass",
      })
      .then((res) => {
        this.activityClass = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.applicationPayment.getAll(params);
  }

  // 上传发票
  handleSuccess() {
    this.$message({
      type: "success",
      message: "上传成功!",
    });
    this.fetchData(this.queryForm);
  }
  handleFail(err: any) {
    let myError = err.toString();
    myError = myError.replace("Error: ", "");
    myError = JSON.parse(myError);
    this.$message({
      type: "error",
      message: myError.error.message,
    });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.detailId = id;
    this.$router.push({
      name: "applicationPaymentDetail",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 编辑
  handleEdit(id: number) {
    this.$router.push({
      name: "applicationPaymentEdit",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 确认拨款
  handleConfirm(id: number) {
    api.applicationPayment
      .checkFunding({
        body: { id: id },
      })
      .then((res: any) => {
        this.id = id;
        api.tenantBankAccount.getAll({ maxResultCount: 1000 }).then((res) => {
          if (res && res.items) {
            this.accountList = res.items;
            this.accountList.forEach((item: any) => {
              item.name =
                "开户行名称：" +
                item.bankName +
                "，开户行账号：" +
                item.bankAccountCode;
            });
          }
        });

        if (!res) {
          this.$confirm(
            "当前用款申请发票验证成功。请确认是否继续拨付",
            "验证成功"
          ).then(async () => {
            this.isAccountShow = true;
          });
        } else {
          this.$confirm(
            "当前用款申请发票验证失败，原因：" + res + "。请确认是否继续拨付",
            "验证失败"
          ).then(async () => {
            this.isAccountShow = true;
          });
        }
      })
      .catch(() => {
        console.log("checkFunding failure");
      });
  }

  trueConfirm() {
    api.applicationPayment
      .confirmFunding({
        body: {
          id: this.id,
          tenantBankAccountId: this.tenantBankAccountId,
        },
      })
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.isAccountShow = false;
        this.fetchData(this.queryForm);
      });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    let scope: AuditFlowScope = row.auditFlowScope;

    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope[scope],
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  // 设置发票号
  handleSetInvoiceNumbers(id: number) {
    this.isShow = true;
    this.dataId = id;
    this.getInvoiceNumbers(id);
  }

  getInvoiceNumbers(id: number) {
    api.applicationPayment
      .getInvoiceNumbers({
        id: id,
      })
      .then((res: any) => {
        this.invoiceNumbers = res;
      });
  }

  // 查看发票号
  handleViewInvoiceNumbers(id: number) {
    this.isViewShow = true;
    this.getInvoiceNumbers(id);
  }

  addInvoice() {
    this.invoiceNumbers.push(this.invoiceNumber);
    this.invoiceNumber = "";
  }

  setNum() {
    if (!this.invoiceNumbers || this.invoiceNumbers.length <= 0) {
      this.$message.error("请先点击确认添加按钮");
      return;
    }
    api.applicationPayment
      .setInvoiceNumbers({
        body: {
          id: this.dataId,
          invoiceNumbers: this.invoiceNumbers,
        },
      })
      .then(() => {
        this.isShow = false;
        this.invoiceNumber = "";
        this.$message.success("操作成功");
      });
  }

  deleteRow(index: number) {
    this.invoiceNumbers.splice(index, 1);
  }

  cancel() {
    this.isShow = false;
    this.invoiceNumber = "";
  }
}
